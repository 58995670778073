/* Bootstrap overrides
-------------------------------------------------- */

// override themeVariables below

$body: #000;
$headings-font-weight: 300 !important;
$primary: #000;
$light: #fafafa;
$muted: #9ba5b4;

$spacer-val: 1rem * 2;

// SPACING
$spacers: (
  spacer: (
    $spacer-val,
  ),
);

// CARDS
$card-spacer-x: 1.5rem;
$card-border-radius: 0.45rem;
$card-inner-border-radius: 0.45rem;

$font-family-sans-serif: Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 3.25rem;
$input-btn-font-size-lg: 1rem;

// COMMON

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 3.25rem;
$input-btn-font-size-lg: 1rem;

// BUTTON
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.35rem;
$btn-font-size: 0.7rem;

$btn-font-weight: 300;
$btn-padding-y-sm: 0.55rem;
$btn-padding-x-sm: 1.15rem;
$btn-font-size-sm: 0.7rem;

// MODAL
$modal-content-border-radius: $card-border-radius;

/* Do not move the imports from here. 
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";

// Dapp-utils
@import "./../../../node_modules/@elrondnetwork/dapp-utils/dist/dapp-utils.scss";

// Glitch
@import "./glitch";

@import "../../components/ModalContainer/modalContainer.scss";

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 16px;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Elements
-------------------------------------------------- */

.home {
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    img {
      display: inline;
      height: 60px;
    }
    span {
      flex-basis: 0;
      text-align: center;
      padding: 0.5rem 0 0.5rem 0;
    }
    em {
      margin: 0.5rem 1rem 0.25rem 1.5rem;
    }
    .exchange-text {
      img {
        margin-top: 0.4rem;
        border-left: 1px solid $white;
        padding-left: 1rem;
        max-height: 40px;
      }
    }
    &.logo-text {
      img {
        max-height: 50px;
      }
      .exchange-text {
        img {
          max-height: 35px;
        }
      }
      @include media-breakpoint-down(sm) {
        img {
          max-height: 30px;
        }
        .exchange-text {
          img {
            margin-top: 0.2rem;
            max-height: 20px;
          }
        }
      }
      @include media-breakpoint-up(sm) {
        margin-left: -0.3rem;
      }
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;

      span {
        flex-shrink: 0;
        flex-basis: 20rem;

        &:first-child {
          text-align: right;
        }

        &:last-child {
          text-align: left;
        }
      }
    }
  }

  .lead {
    font-size: 1.3rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a.parent {
    color: #fff;
    border-bottom: #fff 1px dotted;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      border-bottom: #fff 1px solid;
    }
  }
  .btn {
    margin-top: 1rem;
    border-radius: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 14.375rem;
  }

  .btn-outline-light {
    color: #fff;
    &:hover {
      color: #111;
    }
  }

  .blur {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.65rem;
    overflow: hidden;
  }
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .blur {
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      background-color: transparent;
    }
  }
}

.page-state {
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
}

.btn {
  &.btn-lg {
    border-radius: 2rem;
  }
  &.btn-close {
    @include button-variant($light, $gray-200);
    color: $muted;
    padding: 0.1rem 0.2rem 0.2rem 0.2rem;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 200;
    box-shadow: none;
    border: 0;
  }
}

@include media-breakpoint-up(sm) {
  .w-sm-100 {
    width: 100% !important;
  }
}

a.leaderboard {
  font-weight: normal;
  text-decoration: none;
  color: #fff;
  border-bottom: #fff 1px dotted;

  &:hover {
    text-decoration: none;
    border-bottom: #fff 1px solid;
  }
}
